.left{
    width: 270px;
    float: left;
    clear: both;
    height: 600px;
    position: relative;
    overflow: hidden;
    margin-left: 0px;
}
.sidebar{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    /* width: 200px; */
    font-size: 12px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1;
}
.secondary_nav{
    margin: 10px;
}
.sidenav{
    list-style: none;
    padding-left: 0;
}
.secondary_nav ul li{
    margin-bottom: 2px;
    position: relative;
}
.secondary_nav ul li a{
    border-top: #d6d6d6 1px solid;
    border-right: #bfbfc0 1px solid;
    border-bottom: #babbbb 1px solid;
    border-left: #bfbfc0 1px solid;
    color: #444;
    background: #fff url(../sprite/layout-sprite.png) repeat-x;
    background-position: 0 -251px;
    line-height: 35px;
    display: block;
    text-decoration: none;
    padding-left: 38px;
}
.secondary_nav ul li a:hover{
cursor: pointer;
border: #b5ccd4 1px solid;
color: #084e70;
background-position: 0 -289px;
}

.nav_icon{
    background: url(../sprite/icon-black.png) no-repeat;
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
}


.computer_imag{
    background-position: -420px -60px !important;
}
.frames{
    background-position: 0px -80px !important;
}
.blocks_images{
    background-position: -20px -80px !important;
}
.list_images{
    background-position: -40px -80px !important;
}
.coverflow{
    background-position: -60px -80px !important;
}
.bulls_eye{
    background-position: -180px -20px !important;
}
.list_image{
    background-position: -80px -80px !important;
}

@media screen  and (min-width: 769px) and (max-width: 1024px) {
    .left{
        width: 200px !important;
    }
    .sidebar {
        margin-left: 0px !important;
    }
    
    
}