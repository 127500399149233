.container {
    min-height: 1000px;
    background: #f9f9f9;
    border-left: #aeaeaf 1px solid;
    -moz-box-shadow: 0 0 2px #ccc;
    -webkit-box-shadow: 0 0 2px #ccc;
    box-shadow: 0 0 2px #ccc;
    overflow: hidden;
}

.container1 {
    height: 100vh;
    background: #f9f9f9;
    border-left: #aeaeaf 1px solid;
    -moz-box-shadow: 0 0 2px #ccc;
    -webkit-box-shadow: 0 0 2px #ccc;
    box-shadow: 0 0 2px #ccc;
    overflow-y: scroll;
}

.grid_container {
    width: 100%;
}

.item_widget {
    width: 100%;
    overflow: hidden;
    padding: 20px 10px 0 10px;
}

.item_block {
    width: auto;
    height: 180px;
    text-align: center;
    float: left;
    background: #fff;
    border: #ddd 1px solid;
    padding: 15px;
    margin-right: 18px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}



.icon_block {
    width: 80px;
    height: 80px;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    border-radius: 50% !important;
    padding: 10px;
    margin: auto;
}


.item_icon {
    width: 60px;
    height: 60px;
    background: #e3ebef;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    border-radius: 50% !important;
    display: block;
    position: relative;
}

.item_icon:hover {
    background: #fff !important;
}

.item_icon span {
    width: 40px;
    height: 40px;
    position: absolute;
    background: url(../sprite/icon_sl.png) no-repeat;
    display: block;
    top: 10px;
    left: 10px;
}


.green_block {
    background: #9ec22d;
}

.icon_msg {
    width: 40px;
    height: 40px;
    position: absolute;
    background: url(../sprite/icon_sl.png) no-repeat;
    display: block;
    top: 10px;
    left: 10px;
    background-position: -440px 0px !important;
}

.blue_block {
    background: #1889cb;
}

.orange_block {
    background: #c16a1c;

}

.gray_block {
    background: #b5b5b5;
}

.violet_block {
    background: #AB56E7;
}

.tur_block {
    background: #3B9C9C;
}

.brown_block {
    background: #A52A2A;
}


.contact_sl {

    background-position: -440px 0px !important;
}

.cost_sl {
    background-position: -400px 0px !important;
}

.basket_sl {
    background-position: -40px -40px !important;
}

.lightbulb_sl {
    background-position: -800px -40px !important;
}

.finished_work_sl {
    background-position: -400px -80px !important;
}

.administrative_docs_sl {
    background-position: -240px -40px !important;
}

.communication_sl {
    background-position: -520px 0px !important;
}

.item_icon span a {
    cursor: pointer;
    height: 100px;
    width: 130px;
    display: block;
    position: absolute;
    top: -35px;
    left: -46px;
    text-indent: -9999999px;
    text-decoration: none;
    color: #06C;
}

.item_block h3 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 16px;
    color: #056fad !important;
    line-height: 1.8em;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .item_widget .item_block {
        width: 140px !important;
        margin: 10px !important;
    }

}