body {
	font-size: 12px;
	font-family: Arial, sans-serif;
	color: #333;
}

p {
	font-size: 1em;
	/* 12px */
	line-height: 1.5em;
	/* 18px */
	margin: 0 0 1.5em 0;
}

h1 {
	font-size: 2em;
	/* 24px */
	line-height: 1.5em;
	/* 36px */
	margin-top: 0.75em;
	margin-bottom: 0em;
}

h2 {
	font-size: 1.8333em;
	/* 22px */
	line-height: 1.6364em;
	/* 36px */
	margin-top: 0.8182em;
	margin-bottom: 0em;
}

h3 {
	font-size: 1.6667em;
	/* 20px */
	line-height: 1.8em;
	/* 36px */
	/* margin-top: 0.9em; */
	margin-bottom: 0em;
}

h4 {
	font-size: 1.5em;
	/* 18px */
	line-height: 1em;
	/* 18px */
	margin-top: 1em;
	margin-bottom: 0em;
}

h5 {
	font-size: 1.3333em;
	/* 16px */
	line-height: 1.125em;
	/* 18px */
	margin-top: 1.125em;
	margin-bottom: 0em;
}

h6 {
	font-size: 1.1667em;
	/* 14px */
	line-height: 1.2857em;
	/* 18px */
	margin-top: 1.2857em;
	margin-bottom: 0em;
}

blockquote {
	padding: 10px 15px;
	border-left: 4px solid #ccc;
	margin: 20px 20px;
}

blockquote cite {
	font-size: .9em;
}

blockquote p {
	padding: 0px !important;
	margin: 0px !important;
}

blockquote.quote_blue {
	padding: 10px 15px;
	border-left: 4px solid #09F !important;
	margin: 20px 20px;
	border-bottom: #09F 1px solid !important;
	border-top: #09F 1px solid !important;
	border-right: #09F 1px solid !important;
	background: #e7f2f8;
	color: #06C;
}

blockquote.quote_orange {
	padding: 10px 15px;
	border-left: 4px solid #F30 !important;
	margin: 20px 20px;
	border-bottom: #F30 1px solid !important;
	border-top: #F30 1px solid !important;
	border-right: #F30 1px solid !important;
	background: #fde5de;
	color: #cc2d00;
}

blockquote.quote_pink {
	padding: 10px 15px;
	border-left: 4px solid #FF1493 !important;
	margin: 20px 20px;
	border-bottom: #FF1493 1px solid !important;
	border-top: #FF1493 1px solid !important;
	border-right: #FF1493 1px solid !important;
	background: #fff;
	color: #FF1493;
}

.page_content blockquote {
	margin: 20px 0px;
}

blockquote {
	border-bottom: #ccc 1px solid;
	border-top: #ccc 1px solid;
	border-right: #ccc 1px solid;
}

.primary_h {
	border-bottom: #ccc 1px solid;
	padding: 0 0 5px 0 !important;
	margin: 15px 20px;
}

.box_h {
	border: #ccc 1px solid;
	background: #fff;
	padding: 0px 10px !important;
	margin: 15px 20px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.page_content .primary_h,
.page_content .box_h {
	margin: 15px 0;
}

.inline_code {
	border: #F99 1px solid;
	padding: 0px 5px 2px 5px;
	background: #FCF;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	display: inline-block;
	color: #000;
}

.widget_content h1,
.widget_content h2,
.widget_content h3,
.widget_content h4,
.widget_content h5,
.widget_content h6 {
	font-weight: normal;
	padding: 0 20px;
}

.page_content h1,
.page_content h2,
.page_content h3,
.page_content h4,
.page_content h5,
.page_content h6 {
	font-weight: normal;
}

.widget_content p {
	padding: 0 20px;
}

.widget_content .page_content p {
	padding: 0
}

.widget_content .page_content h1,
.widget_content .page_content h2,
.widget_content .page_content h3,
.widget_content .page_content h4,
.widget_content .page_content h5,
.widget_content .page_content h6 {
	padding: 0;
}

.widget_content .page_content {
	margin: 0 10px
}

.widget_content .page_content .code_syn {
	margin: 20px 0px;
}

.page_content {
	width: auto;
	overflow: hidden;
	clear: both;
}

.page_content .code_syn {
	margin: 20px 0;
	border: #ddd 1px solid;
	background: #fff;
	/*	border-radius:4px;
	-moz-border-radius:4px;
	-webkit-border-radius:4px;
*/
}

.tick_icn,
.alarm_icn,
.award_icn,
.help_icn,
.clip_icn,
.err_icn,
.flag_icn,
.folder_icn,
.globe_icn,
.heart_icn,
.info_icn,
.medal_icn,
.pin_icn,
.plus_icn,
.trophy_icn,
.decimal_order,
.upper_roman,
.lower_roman,
.upper_alpha,
.lower_alpha {
	margin: 15px;
}

.page_content .tick_icn,
.page_content .alarm_icn,
.page_content .award_icn,
.page_content .help_icn,
.page_content .clip_icn,
.page_content .err_icn,
.page_content .flag_icn,
.page_content .folder_icn,
.page_content .globe_icn,
.page_content .heart_icn,
.page_content .info_icn,
.page_content .medal_icn,
.page_content .pin_icn,
.page_content .plus_icn,
.page_content .trophy_icn,
.page_content .decimal_order,
.page_content .upper_roman,
.page_content .lower_roman,
.page_content .upper_alpha,
.page_content .lower_alpha {
	margin: 15px 0px;
}

.tick_icn li {
	line-height: 18px;
	background: url(../images/list-icons/tick.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.alarm_icn li {
	line-height: 18px;
	background: url(../images/list-icons/alarm.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.award_icn li {
	line-height: 18px;
	background: url(../images/list-icons/award.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.help_icn li {
	line-height: 18px;
	background: url(../images/list-icons/ask.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.clip_icn li {
	line-height: 18px;
	background: url(../images/list-icons/clip.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.err_icn li {
	line-height: 18px;
	background: url(../images/list-icons/err.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.flag_icn li {
	line-height: 18px;
	background: url(../images/list-icons/flag.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.folder_icn li {
	line-height: 18px;
	background: url(../images/list-icons/folder.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.globe_icn li {
	line-height: 18px;
	background: url(../images/list-icons/globe.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.heart_icn li {
	line-height: 18px;
	background: url(../images/list-icons/heart.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.info_icn li {
	line-height: 18px;
	background: url(../images/list-icons/info.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.medal_icn li {
	line-height: 18px;
	background: url(../images/list-icons/medal-gold.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.pin_icn li {
	line-height: 18px;
	background: url(../images/list-icons/pin.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.plus_icn li {
	line-height: 18px;
	background: url(../images/list-icons/plus.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.trophy_icn li {
	line-height: 18px;
	background: url(../images/list-icons/trophy.png) no-repeat 0 -2px;
	padding-left: 24px;
}

.decimal_order li {
	list-style: decimal;
	margin-left: 20px;
}

.upper_roman li {
	list-style: upper-roman;
	margin-left: 20px;
}

.lower_roman li {
	list-style: lower-roman;
	margin-left: 20px;
}

.upper_alpha li {
	list-style: upper-alpha;
	margin-left: 20px;
}

.lower_alpha li {
	list-style: lower-alpha;
	margin-left: 20px;
}

.widget_content .code_syn {
	margin: 20px;
	border: #ddd 1px solid;
	background: #fff;
	/*	border-radius:4px;
	-moz-border-radius:4px;
	-webkit-border-radius:4px;
*/
}


/*---DROP CAP---*/
p .dropcap {
	float: left;
	font-size: 48px;
	display: block;
	line-height: 80%;
	margin: 3px 8px 3px 0px;
	padding: 0px;
	color: #275683;
}

p .dropcapM {
	float: left;
	font-size: 300%;
	display: block;
	line-height: 80%;
	margin: 3px 8px 3px 0px;
	padding: 0px;
	color: #a32407;
}

p .dropcapB {
	float: left;
	font-size: 48px;
	text-align: center;
	width: 50px;
	height: 50px;
	color: #fff;
	display: block;
	line-height: 50px;
	margin: 3px 8px 3px 0px;
	padding: 10px;
	border-radius: 100px;
	background: #333;
}

p .dropcapC {
	float: left;
	font-size: 36px;
	text-align: center;
	width: 30px;
	height: 30px;
	color: #fff;
	display: block;
	line-height: 30px;
	margin: 3px 8px 3px 0px;
	padding: 10px;
	border-radius: 100px;
	background: #0e354a;
}

p .dropcapG {
	float: left;
	font-size: 24px;
	text-align: center;
	width: 30px;
	height: 30px;
	color: #fff;
	display: block;
	line-height: 30px;
	margin: 3px 8px 3px 0px;
	padding: 10px;
	border-radius: 100px;
	background: #a33b13;
}

p .dropcapL {
	float: left;
	font-size: 18px;
	text-align: center;
	width: 18px;
	height: 18px;
	color: #333;
	display: block;
	line-height: 18px;
	margin: 3px 8px 3px 0px;
	padding: 10px;
	border-radius: 100px;
	background: #ddd;
	border: #333 1px solid;
}