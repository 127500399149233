.mainicon {
  width: 500px;
  float: left;
  margin-left: 80px;
  height: 60px;
}

.dashicon {
  width: 150px;
  float: left;
  height: 60px;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  margin-top: 0px;
  color: white;
  border-radius: 0px;
  margin-left: 0px;
}

.dashicon:hover {
  background-color: #384EA3;
  color: white;
}

.supporticon {
  width: 150px;
  float: left;
  height: 60px;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  margin-top: 0px;
  color: white;
  border-radius: 0px;
  margin-left: 0px;
}

.supporticon:hover {
  background-color: #384EA3;
  color: white;
}

.creative-table-container {
  padding: 20px;
}

.creative-table {
  font-size: 16px;
}

.action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  font-size: 18px;
  color: #007bff;
  /* Blue color for icons */
}

.action-button:hover {
  text-decoration: underline;
}





.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
  margin-right: 30px;
}

/* Style pagination items */
.pagination a {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

/* Style the active pagination item */
.pagination .active a {
  background-color: #007bff;
  color: #fff;
}

/* Style the "Previous" and "Next" buttons */
.pagination .previous,
/* .pagination .next {
    margin: 0 0px;
    padding: 0px 0px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  } */

/* Style the "Previous" and "Next" buttons on hover */
.pagination .previous:hover,
/* .pagination .next:hover {
    background-color: #007bff;
    color: #fff;
  } */

/* Style the ellipsis (...) */
.pagination .break {
  margin: 0 5px;
  font-weight: bold;
}

/* Style the disabled items */
.pagination .disabled a {
  pointer-events: none;
  color: #ccc;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
}



.fullbody {
  width: 100%;
  height: auto;
}

.treestyle {
  width: 15%;
  float: left;
  margin-top: 20px;
}

.centerimgpart {
  width: 85%;
  float: left;
}


.tree-view {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.tree {
  list-style-type: none;
  padding-left: 20px;
  border-left: 1px solid #333;
}

.tree li {
  margin: 5px 0;
}

.tree li::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #333;
  border-radius: 50%;
  background-color: #333;
}

.tree li:last-child::before {
  border: none;
}

/* Custom styling for different tree levels */
.tree ul {
  padding-left: 0;
  border-left: none;
}


.ordertable {
  text-align: center;
}


.fullbody {
  width: 100%;
  height: auto;
}

.imgtableorder {
  margin-top: 20px;
}




.buttondiv {
  margin-top: 20px;
}

.button {
  margin-left: 50px;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
}

.button:hover {
  background-color: darkgray;
  color: black;
  border: 1px solid;
  box-shadow: 5px 5px 0px 0px #cccc;
}



#form-file-upload {
  height: 10rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

/* .upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}
.upload-button:hover {
  text-decoration-line: underline;
} */



/* .beautiful-form {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.upload-container {
  text-align: center;
}

.upload-label {
  display: inline-block;
  border: 2px dashed #007BFF;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.drag-drop-box p {
  margin: 0;
  font-size: 16px;
}

.upload-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  float: left;
}

.upload-button:hover {
  background-color: #0056b3;
}

.file-input {
  display: none;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Add styles for hover and drag-over effects on the drop box */
.upload-label:hover {
  background-color: #d9edff;
  border: 2px dashed #0056b3;
}

.upload-label.dragover {
  background-color: #d9edff;
  border: 2px dashed #0056b3;
}

#message-box {
  height: 150px;
}





.side-menu {
  width: 250px;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  z-index: 999;
}

.side-menu.expanded {
  left: 0;
}

.menu-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.menu-header h2 {
  margin: 0;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  position: relative;
}

.menu-list a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.sub-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sub-menu li {
  padding: 10px;
  text-align: center;
}

.sub-menu a {
  text-decoration: none;
  color: #555;
  font-size: 16px;
  display: block;
}

.graphbar {
  width: 100%;
  padding: 20px;
  float: left;
  clear: both;
}

.bar {
  width: 100%;
  padding: 20px;
  float: left;
}

.bg-custom {
  background-color: #e7e5f5;
  font-size: 16px;
  font-weight: 600;
}

.cus-border {
  border: 2px solid green !important;
  padding: 0;
}

/* tr:nth-child(1) {
  background-color: #8345fd !important;
} */

.invoce-tittle {
  font-size: 22px !important;
  font-weight: 600;
  color: #555;
  background-color: #e7e5f5;
}

hr {
  color: #7a1c1c !important;
  border-top: 1px solid #333;
}


.tableview>table td {
  font-size: 16px !important;
}

.tableview>table tr {
  border: 1px solid grey;
}

.table-color-cu {
  background: #E6F2EE !important;
}


.form-check-input {
  border: 1px solid grey !important;
}

.add-job-img {
  width: 200px;
  height: 200px;
}

select {
  margin-top: 6px;
}

ul>.nav-item {
  border: 1px solid;
  border-radius: 5px;
  background: gainsboro;
  color: white
}

input {
  padding: 10px 12px !important;
}

.custom-card>.card-body {
  background-color: #e6f2f7 !important;
}

.custom-cards>.card-body {
  background-color: #fde1e1 !important;
}

.card-button {
  background: #e5faf1 !important;
  color: #048347;
  border: 1px solid #D3F2E4;
  border-radius: 15px 20px 5px 15px;
}

.card-button:hover {
  color: #048347 !important;
}

.card-button:focus {
  color: #0056b3 !important;
}

.btn-outline-primary{
  border: 1px solid #0056b3;
  margin: 6px;
}
.vertical-timeline{
margin-bottom: 18em !important;
}

h2.accordion-header{
  font-size: 0; 
     line-height:0;
     margin-top: 0; 
     margin-bottom: 0;
}

.tab-content{
  overflow-x: auto;
}

/* Loading popup starts hear */

/* Loading popup starts hear */

.loading-spinner-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Use your desired spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}