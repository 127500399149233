/* Modal.css */
.modal {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-y: auto;

}

.modal.open {
  display: flex;
  overflow: auto;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 500px;
  max-height: 600px;
  overflow: auto;

}

.modal-contentses {
  background-color: #f7f6fc;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-top: 40px;
  max-height: 600px;
  width: 40%;
  overflow: auto;
}

.modal-contents {
  background-color: #f7f6fc;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-top: 40px;
  max-height: 600px;
  overflow: auto;

}

.modal-contente {
  background-color: #f7f6fc;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-top: 40px;
  max-height: 90%;
  overflow: auto;
  width: 70%;
}

.modal-contentes {
  background-color: #f7f6fc;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-top: 40px;
  max-height: 600px;
  width: 50%;
  overflow: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}



.form1 {
  margin: 20px;
  font-family: Arial, sans-serif;
}

label {
  font-weight: bold;
  margin-right: 10px;
}

select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
}

/* Style for the dropdown arrow */
select::-ms-expand {
  display: none;
}

/* Style for the selected option */
select option {
  background-color: #fff;
  color: #333;
}

.newordertable>table tr td {
  color: #0173B0 !important;
}

.border-cus {
  border: 1px solid gray;
}

.table>:not(caption)>*>*{
  box-shadow: none;
  border: 1px solid gray;
}