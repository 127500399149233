.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #4caf50;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.company-info {
  display: flex;
  align-items: center;

  .company-logo {
    width: 50px;
    /* Adjust the size as needed */
    margin-right: 10px;
  }

  div {
    max-width: 300px;
    /* Adjust the width as needed */
  }

  h2 {
    margin: 0;
    font-size: 1.2em;
  }

  p {
    margin: 5px 0;
    font-size: 0.8em;
    color: #ddd;
  }
}

.invoice-info {
  text-align: right;

  h1 {
    margin: 0;
    font-size: 1.5em;
  }

  .date {
    margin: 5px 0;
    color: #ddd;
  }
}

.invoice-container {
  font-family: 'Roboto', sans-serif;
  max-width: 400px;
  margin: 20px auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header {
  padding: 20px;
  text-align: center;
  background-color: #4caf50;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.header h1 {
  margin: 0;
}

.date {
  margin: 5px 0;
  color: #ddd;
}

.content {
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid #ddd;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #f5f5f5;
    }
  }
}

.footer {
  padding: 20px;
  text-align: right;
}

.total {
  font-weight: bold;
  font-size: 1.2em;
  color: #4caf50;
}

@media print {

  /* Styles for printing */
  .footer button {
    display: none;
    /* Hide the print button in the printed version */
  }
}

@media print {
  body {
    margin: 0;
    /* Reset margin for the entire page */
    padding: 0;
    /* Reset padding for the entire page */
  }

  .invoice-container {
    width: 100%;
    /* Use the entire page width for the invoice */
    padding: 20px;
    /* Add padding to the invoice container for better readability */
  }

  /* Add more specific styles as needed */
}